import { pruneObject } from '../helpers/pruneObject';
import { translate } from '../helpers/translate';
import type { Constructable, DocumentSourced, Identified, PartialMaybe, PartialRecursive, Timestamped } from './_';
import type { AbstractCollection } from './Collection';
import {
  collection,
  type CollectionReference,
  deleteDoc,
  deleteField,
  type DocumentData,
  type DocumentReference,
  type DocumentSnapshot,
  type FieldPath,
  type FieldValue,
  getDoc,
  getDocFromCache,
  getDocFromServer,
  increment,
  onSnapshot,
  type PartialWithFieldValue,
  serverTimestamp,
  setDoc,
  type SetOptions,
  type UpdateData,
  updateDoc,
  type WithFieldValue,
  type WriteBatch,
} from 'firebase/firestore';
import { Observable } from 'rxjs';

export abstract class AbstractDocument<T> {
  // eslint-disable-next-line @typescript-eslint/ban-types
  readonly collections: Record<string, AbstractCollection<any, {}>> = {};
  readonly reference: DocumentReference<T> = null;

  readonly translatable?: (data: any) => unknown;

  protected batch: WriteBatch = null;

  constructor(reference: DocumentReference<T>, batch?: WriteBatch) {
    if (batch != null) {
      this.batch = batch;
    }

    this.reference = reference;
  }

  /**
   * Basename of the document.
   */
  get id() {
    return this.reference.id;
  }

  /**
   * Native Firestore instance.
   */
  get db() {
    return this.reference.firestore;
  }

  /**
   * Absolute path to this (sub-)document.
   */
  get path() {
    return this.reference.path;
  }

  /**
   * @param id Basename of the sub-collection.
   */
  collection<S = DocumentData>(id: string) {
    return collection(this.reference, id) as CollectionReference<S>;
  }

  /**
   * Creates a document with the supplied `data` and returns itself.
   *
   * @param data The data to write.
   */
  create(data: WithFieldValue<PartialMaybe<T, Identified & Timestamped>>) {
    return this.set(data, false).then(() => this);
  }

  /**
   * Atomically decrements the value at the specified path by one.
   *
   * @param path Dot-separated path to decrement.
   */
  decrement(path: keyof UpdateData<T>) {
    return this.update({ [path]: increment(-1) } as UpdateData<T>);
  }

  /**
   * Deletes the document and returns how many documents were deleted.
   *
   * @param deep Deletes all sub-collections as well.
   */
  async delete(deep = true) {
    let result = 0;

    if (deep === true) {
      const promises: Promise<number>[] = [];

      for (const collection in this.collections) {
        promises.push(this.collections[collection].delete(deep));
      }

      await Promise.all(promises).then((counts) => {
        for (const count of counts) {
          result += count;
        }
      });
    }

    if (this.batch == null) {
      try {
        await deleteDoc(this.reference);
      } catch (error) {
        if (error instanceof Error) {
          if (error.message.includes(this.path) !== true) {
            error.message = [`Failed to delete() on '${this.path}'.`, error.message].join('\n');
          }
        }

        throw error;
      }
    } else {
      this.batch.delete(this.reference);
    }

    return ++result;
  }

  get(data: false): Promise<DocumentSnapshot<T>>;
  get(data?: true): Promise<T>;

  /**
   * Returns a promise containing either the typed document or a snapshot.
   *
   * @param data If `false`, returns a `DocumentSnapshot` instead.
   */
  get(data?: boolean) {
    return getDoc(this.reference).then((document) => (data === false ? document : document.data()));
  }

  /**
   * Returns a promise with the typed document.
   * The most adequate translation overwrites the original content.
   *
   * @param locale The target language (BCP 47).
   */
  getTranslated(locale?: string): Promise<T> {
    return this.get(true).then((data) => translate(data, locale));
  }

  /**
   * Returns an object with all translatable properties.
   * This needs to be implemented in each supported model.
   */
  async getTranslatableProps(snapshot?: T): Promise<PartialRecursive<T>> {
    if (this.translatable == null) {
      throw new Error(`Method not implemented. Translatable properties need to be defined in ${this.reference.parent.path} model.`);
    }

    return pruneObject(this.translatable(snapshot != null ? snapshot : await this.get(true)));
  }

  /**
   * Atomically increments the value at the specified path by one.
   *
   * @param path Dot-separated path to increment.
   */
  increment(path: keyof UpdateData<T>) {
    return this.update({ [path]: increment(+1) } as UpdateData<T>);
  }

  observe(data: false, source?: false): Observable<DocumentSnapshot<T>>;
  observe(data: false, source?: true): Observable<DocumentSourced<DocumentSnapshot<T>>>;
  observe(data?: true, source?: false): Observable<T>;
  observe(data?: true, source?: true): Observable<DocumentSourced<T>>;

  /**
   * Returns an observable containing either the typed document or a snapshot.
   *
   * @param data If `false`, returns a `DocumentSnapshot` instead.
   * @param source If `true`, returns `local` if there are pending writes, or `server` otherwise.
   */
  observe(data?: boolean, source = false) {
    return new Observable((subscriber) => {
      const callback = (snapshot: DocumentSnapshot<T>) => {
        const result = data === false ? snapshot : snapshot.data();

        if (source !== true) {
          return subscriber.next(result);
        }

        return subscriber.next({
          result,
          source: snapshot.metadata.hasPendingWrites ? 'local' : 'server',
          fromCache: snapshot.metadata.fromCache,
        });
      };

      return onSnapshot(this.reference, callback, (error: Error) => subscriber.error(error));
    });
  }

  observeTranslated(locale?: string, source?: false): Observable<T>;
  observeTranslated(locale?: string, source?: true): Observable<DocumentSourced<T>>;

  /**
   * Returns an observable containing the typed document.
   * The most adequate translation overwrites the original content.
   *
   * @param locale The target language (BCP 47).
   * @param source If `true`, returns `local` if there are pending writes, or `server` otherwise.
   */
  observeTranslated(locale?: string, source = false) {
    return new Observable((subscriber) => {
      const callback = (snapshot: DocumentSnapshot<T>) => {
        const result = translate(snapshot.data(), locale);

        if (source !== true) {
          return subscriber.next(result);
        }

        return subscriber.next({
          result,
          source: snapshot.metadata.hasPendingWrites ? 'local' : 'server',
        });
      };

      return onSnapshot(this.reference, callback, (error: Error) => subscriber.error(error));
    });
  }

  /**
   * Removes a one or multiple dot-separated properties from the document.
   * WARNING: These arguments can be misleading, be sure to know what you're doing!
   *
   * @param path Dot-separated paths to remove.
   */
  remove(...paths: (keyof UpdateData<T>)[]) {
    const data: Record<PropertyKey, FieldValue> = {};

    for (const path of paths) {
      data[path] = deleteField();
    }

    return this.update(data as UpdateData<T>);
  }

  /**
   * Writes the supplied `data` to the document and returns the document ID.
   *
   * @param data The data to write.
   * @param merge Merges the entire object if `true`, or replaces the specified paths.
   */
  async set(data: PartialWithFieldValue<PartialMaybe<T, Identified & Timestamped>>, merge: boolean | (string | FieldPath)[] = true) {
    const options: SetOptions = {
      [typeof merge === 'boolean' ? 'merge' : 'mergeFields']: merge,
    };

    if (this.batch == null) {
      try {
        await setDoc(this.reference, data as PartialWithFieldValue<T>, options);
      } catch (error) {
        if (error instanceof Error) {
          if (error.message.includes(this.path) !== true) {
            error.message = [`Failed to set() on '${this.path}'.`, error.message].join('\n');
          }
        }

        throw error;
      }
    } else {
      this.batch.set(this.reference, data as PartialWithFieldValue<T>, options);
    }

    return this.reference.id;
  }

  /**
   * Updates properties specified by keys in `data` with their values and returns the document ID.
   * WARNING: These arguments can be misleading, be sure to know what you're doing!
   *
   * @param data Key-value dot-separated path and corresponding values to write.
   */
  async update(data: PartialWithFieldValue<T> | UpdateData<T>) {
    if (this.batch == null) {
      try {
        await updateDoc(this.reference, data as UpdateData<DocumentData>);
      } catch (error) {
        if (error instanceof Error) {
          if (error.message.includes(this.path) !== true) {
            error.message = [`Failed to update() on '${this.path}'.`, error.message].join('\n');
          }
        }

        throw error;
      }
    } else {
      this.batch.update(this.reference, data as UpdateData<DocumentData>);
    }

    return this.reference.id;
  }
}

/**
 * Reads the document from the cache.
 * If no local cache exists for the document, reads the document from the server.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function Cached<T extends AbstractDocument<{}>>(target: Constructable<T>) {
  const get = target.prototype.get;

  target.prototype.get = async function (data?: boolean) {
    let document: DocumentSnapshot;

    try {
      document = await getDocFromCache(this.reference);
    } catch (error) {
      try {
        document = await getDocFromServer(this.reference);
      } catch (error) {
        // eslint-disable-next-line prefer-rest-params
        return get.apply(this, arguments);
      }
    }

    return data === false ? document : document.data();
  };
}

/**
 * Automatically populates the `id` field in the document, if not present.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function Identifiable<T extends AbstractDocument<{}>>(target: Constructable<T>) {
  const create = target.prototype.create;

  target.prototype.create = function (data: any) {
    if (data.id === undefined) {
      data.id = this.id;
    }

    // eslint-disable-next-line prefer-rest-params
    return create.apply(this, arguments);
  };

  return target;
}

/**
 * Automatically populates the `shape` field in the document, if not present.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function Shapeable<T extends AbstractDocument<{}>>(target: Constructable<T>) {
  const create = target.prototype.create;

  target.prototype.create = function (data: any) {
    if (data.shape === undefined) {
      data.shape = '';
    }

    // eslint-disable-next-line prefer-rest-params
    return create.apply(this, arguments);
  };
}

/**
 * Automatically populates the `createdAt` & `updatedAt` fields in the document, if not present.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function Timestampable<T extends AbstractDocument<{}>>(target: Constructable<T>) {
  const create = target.prototype.create;

  target.prototype.create = function (data: any) {
    if (data.createdAt === undefined) {
      data.createdAt = serverTimestamp();
    }

    // eslint-disable-next-line prefer-rest-params
    return create.apply(this, arguments);
  };

  const set = target.prototype.set;

  target.prototype.set = function (data: any, merge: boolean | (string | FieldPath)[] = true) {
    if (data.updatedAt === undefined) {
      data.updatedAt = serverTimestamp();
    }

    if (typeof merge !== 'boolean') {
      if (data.createdAt !== undefined && merge.includes('createdAt') !== true) {
        merge.push('createdAt');
      }

      if (data.updatedAt !== undefined && merge.includes('updatedAt') !== true) {
        merge.push('updatedAt');
      }
    }

    // eslint-disable-next-line prefer-rest-params
    return set.apply(this, arguments);
  };

  const update = target.prototype.update;

  target.prototype.update = function (data: any) {
    if (data.updatedAt === undefined) {
      data.updatedAt = serverTimestamp();
    }

    // eslint-disable-next-line prefer-rest-params
    return update.apply(this, arguments);
  };

  return target;
}

/**
 * Cleans the `authoredBy` field from the document, which is now deprecated.
 *
 * This should progressively clean the database, avoiding having to issue hundreds of millions of
 * writes in bulk.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function Untrackable<T extends AbstractDocument<{}>>(target: Constructable<T>) {
  const set = target.prototype.set;

  target.prototype.set = function (data: any, merge: boolean | (string | FieldPath)[] = true) {
    if (typeof merge !== 'boolean') {
      data.authoredBy = deleteField();

      if (data.authoredBy !== undefined && merge.includes('authoredBy') !== true) {
        merge.push('authoredBy');
      }
    } else if (merge === true) {
      data.authoredBy = deleteField();
    }

    // eslint-disable-next-line prefer-rest-params
    return set.apply(this, arguments);
  };

  const update = target.prototype.update;

  target.prototype.update = function (data: any) {
    data.authoredBy = deleteField();

    // eslint-disable-next-line prefer-rest-params
    return update.apply(this, arguments);
  };

  return target;
}
