import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable, Untrackable } from '../../abstract/Document';
import { type Wedding, Weddings } from '../Weddings';
import type { IVideo } from './Videos.types';

@Identifiable
@Timestampable
@Untrackable
export class Video extends AbstractDocument<IVideo> {
  readonly collections = {};
}

export class Videos extends AbstractCollection<Video, IVideo> {
  static definitions = {
    _: {} as IVideo,
  };

  static path = 'videos';

  constructor(document: Wedding) {
    super(document.collection(Videos.path), Video);
  }

  get wedding() {
    return Weddings._.getById(this.reference.parent.id);
  }
}
