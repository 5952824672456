import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable, Untrackable } from '../../abstract/Document';
import { type Wedding, Weddings } from '../Weddings';
import type { IWedding } from '../Weddings.types';
import type { IBookmark } from './Bookmarks.types';

@Identifiable
@Timestampable
@Untrackable
export class Bookmark extends AbstractDocument<IBookmark> {
  readonly collections = {};
}

/**
 * @deprecated This model is still preliminary, do not use.
 */
export class Bookmarks extends AbstractCollection<Bookmark, IBookmark> {
  static definitions = {
    _: {} as IBookmark,
  };

  static path = 'bookmarks';

  constructor(document: Wedding) {
    super(document.collection(Bookmarks.path), Bookmark);
  }

  get wedding() {
    return Weddings._.getById(this.reference.parent.id);
  }

  /**
   * @deprecated This relies on a preliminary model, do not use.
   *
   * @param wedding Wedding ID to bookmark.
   */
  async bookmark(wedding: IWedding['id']) {
    return this.push(wedding).create({});
  }
}
